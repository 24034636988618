import { mapActions, mapState } from 'vuex'
import { myMixin } from '../../../mixins';

export default {
  mixins: [myMixin],
  data: () => ({
    collections: [],
    item: null,
    showJoin: false,
    dialog: false,
    params: {},
    message: '',
    error: false,
    errors: {},
    approximate: false,
    optionsFilter: [
        { id: 1,    field: 'id',           text: 'ID' },
        { id: 2,    field: 'code',         text: 'Código' },
        { id: 3,    field: 'group',        text: 'Turma' },
        { id: 4,    field: 'group.course', text: 'Curso' },
        { id: 5,    field: 'group.campus', text: 'Campus' },
        { id: 6,    field: 'discipline',   text: 'Disciplina' },
        { id: 7,    field: 'teacher',      text: 'Professor' },
        { id: 8,    field: 'subGroup',     text: 'SubTurma' },
        { id: 9,    field: 'join',         text: 'ID Junção' },
        { id: 10,   field: 'attributions', text: 'ID Atribuição' },
        { id: 11,   field: 'group.baseGroups', text: 'Turma/Cod. Composição' },
    ],
    optionFilter: { id: 3, field: 'group', text: 'Turma' },
    loading: false,
    meta: {},
    itemDelete: {},
    payloadFilter: null,
    model: {},
    show: false,
    showForm: false,
    text: 'Carregando dados...',
    loadingProgress: false,
    textDelete: '',
    deleting: false,
    headers: [
        { text: 'Campus', value: 'group.campus.name'},
        { text: 'Curso', value: 'group.course.name'},
        { text: 'Turno', value: 'group.shift.name'},
        { text: 'Turma', value: 'group', width: '130px', sortable: false,},
        { text: 'Disciplina', value: 'discipline_name', sortable: false,},
        { text: 'Carga Horária', value: 'work_load', width: '130px'},
        { text: 'Professor', value: 'teacherName', width: '150px', sortable: false,},
        { text: 'Ações', value: 'actions', sortable: false, width: '90px'}
    ]
    }),
    
    watch: {
      
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData();
        }
      },

       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;

            if(item_new.group_number == 0){
                this.textDelete = "Deseja excluir " + item_new.group.name + " | " + item_new.discipline_name + "?";
            }else{
                this.textDelete = "Deseja excluir " + item_new.group.name + " | " + item_new.discipline_name + "? As demais subturmas serão reordenadas na sequência numérica!";
            }
              

          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },
    },
    computed: {
        ...mapState('auth', ['user', 'acesso']),
      },
    methods: {
      ...mapActions('grid', ['ActionDeleteGrid', 'ActionDeleteDivisionGrid', 'ActionDeleteAddTeacher', 'ActionFindGrids']),
      ...mapActions('indication', [ 'ActionGetGridJoin']),
        
      getData(){
      
        this.error = false;
        this.message = '';
        this.loadingProgress = true;
        this.showForm = false;
        let payload = this.params;

        delete payload.filter_relations;
        delete payload.find_columns;

        if(this.payloadFilter){
            
            if(this.payloadFilter.filter_relations){
                payload.filter_relations = this.payloadFilter.filter_relations;
            }

            if(this.payloadFilter.find_columns){
                payload.find_columns = this.payloadFilter.find_columns;
            }
        }

        if(!payload.filter_relations){
            payload.filter_relations = [];
        }

        if(!payload.find_columns){
            payload.find_columns = {};
        }

        payload.filter_relations.push(
            {
                name: 'group',
                key: 'status_group_id', 
                value: '1,2',
                clause: 'whereIn'
            }
        );

        payload.with = 'join,attributions.teacher,category,discipline,group.campus,group.course,group.shift,workLoadType,workTimeDate,dates,specificity,subGroup'

        let newPayload = {};
            
        if(this.isAnima() && payload.search && this.optionFilter){
            payload.operator    = this.approximate ? 'LIKE' : '=';
            Object.assign(newPayload, payload);
            Object.assign(newPayload, { search: this.optionFilter.field + ' := ' + payload.search  });
        }else{
            newPayload = payload;
        }
        
        this.ActionFindGrids(newPayload)
            .then((res) => {
                this.collections = res.data;
                this.meta = res.meta;
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
            })
    },

    filter(payload){
        this.payloadFilter = payload;
        this.dialog = false;
        this.getData();
    },

    getAttribution(item, status)
    {
        let find = item.attributions.find(a => a.attribution_status_id == status);
        let att = null;

        if(find){
            
            let teacher = item.in_hiring ? { id: 0, name: 'A Contratar'} : (find.teacher ? find.teacher : { id: 0, name: null });
            
            att = {
                teacher:    teacher,
                date_start: find.date_start,
                date_end:   find.date_end,
            };
        }else{
            if(item.in_hiring){
                return {
                    teacher:    { id: 0, name: 'A Contratar'},
                    date_start: null,
                    date_end:   null,
                };
            }
        }

        return att;
    },

    showJoinGroups(item){
            
        this.item = item;
        
        if(!item.joins){
            this.loadJoins(item).then(() => {
                this.showJoin = true;
            });
        
        }else{
            this.showJoin = true;
        }
        
    },

    async loadJoins(item){
        
        var payload = {
            id:     item.join.join_id,
            with: "grids.join,grids.discipline,grids.group,grids.group.campus,status,grids.workLoadType",
            showAllCampuses: true
        }

        this.loadingProgress = true;

        await this.ActionGetGridJoin(payload).then((res) => {
            this.loadingProgress = false;
            item.joins = res.data.grids;
        });
    },

    deleteItem()
        {
            this.deleting = true;
            this.message = '';

            if(this.itemDelete.group_number == 0 && this.itemDelete.teacher_number == 0){
                this.ActionDeleteGrid({id: this.itemDelete.id})
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                  this.message = error.message;
                  this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
            }else{
                
                var method = this.itemDelete.teacher_number ? 'ActionDeleteAddTeacher' : 'ActionDeleteDivisionGrid';
                
                this[method]({id: this.itemDelete.id})
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                  this.message = error.message;
                  this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
            }
        },
       
       
  },

}
